import React from 'react'
import { Link } from 'gatsby'

import Group from './Group.svg'

const FooterGroup = props => {
  const { white, className, id, ...rest } = props
  return (
    <Link
      id={id || `Global__Footer__Accessibility_Icon`}
      className={className}
      tabIndex={id === 'Global__Footer__Accessibility_Icon' && '0'}
       to="https://levelaccess.com/a/alliedsolutions"
    >
      <img
        src={Group}
        alt="Level Access website accessibility icon"
        width="111"
        height="42"
        {...rest}
      />
    </Link>
  )
}

export { FooterGroup }
